.contact-form-container {
  @apply h-full
    text-theme-bright2-white
    w-screen;
}

.contact-form-container h2 {
  @apply md:text-2xl
    ssm:text-lg
    text-theme-bright2-white
    font-semibold
    text-justify
    ssm:px-5;
}

.contact-form {
  @apply flex
    flex-col
    gap-6
    justify-center
    items-center
    w-full
    text-center
    my-10;
}

.contact-form input {
  @apply h-12
    md:w-[45%]
    ssm:w-[90%]
    border 
    border-gray-300 
    text-gray-900 
    text-lg 
    rounded-lg 
    focus:ring-blue-500 
    focus:border-blue-500 
    block 
    p-2.5 
    dark:bg-gray-700 
    dark:border-gray-600 
    dark:placeholder-gray-400 
    dark:text-white 
    dark:focus:ring-blue-500 
    dark:focus:border-blue-500;
}

.contact-form textarea {
  @apply h-80
    md:w-[45%]
    ssm:w-[90%]
    resize-none
    border 
    border-r-indigo-500
    text-gray-900 
    text-lg 
    rounded-lg 
    focus:ring-blue-500 
    focus:border-blue-500 
    block 
    p-2.5 
    dark:bg-gray-700 
    dark:border-gray-600 
    dark:placeholder-gray-400 
    dark:text-white 
    dark:focus:ring-blue-500 
    dark:focus:border-blue-500;
}

.contact-form button {
  @apply border
     border-pink-600
      hover:bg-pink-600 
      hover:text-white
      focus:ring-4
      focus:outline-none 
      focus:ring-blue-300 
      font-medium 
      rounded-lg 
      text-xl 
      text-pink-600
      sm:w-auto 
      px-5 py-4 
      transition-all
      duration-300;
}

.social-media-container {
  @apply flex
    flex-col
    justify-center
    items-center
    gap-4
    pt-11
    text-2xl
    text-theme-bright2-white
    font-semibold
    text-justify;
}

.social-media-items {
  @apply flex
    flex-wrap
    justify-center
    items-center
    gap-10
    text-center
    p-10;
}

.social-media-items a {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset,
    0px 0px 8px rgba(82, 168, 236, 0.6);
  @apply p-7
    ssm:text-lg;
}

.social-media-items a:hover {
  background: linear-gradient(
    162deg,
    rgba(42, 98, 45, 1) 0%,
    rgba(31, 90, 101, 1) 40%,
    rgba(18, 23, 121, 1) 100%
  );
}

.social-media-icon {
  @apply md:text-9xl
    ssm:text-7xl
    text-theme-bright2-white
    mb-3;
}

.divider-line {
  @apply w-1/2
    h-1
    bg-theme-bright2-white
    m-auto;
}
