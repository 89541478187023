.skills-container {
  @apply text-theme-bright2-white
        md:h-screen
        grid
        justify-center;
}

.skill-logo {
  @apply lg:w-40
    h-auto
    m-0
    block
    text-center
    bg-transparent
    p-3
    transition-all
    duration-300 
    ease-out
    ssm:w-20;
}

.skills-items {
  @apply flex 
    flex-wrap 
    mx-32
    justify-center
    ssm:mx-8;
}
