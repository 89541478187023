.education-item {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
    @apply
    flex
    flex-col
    text-white
    m-5
    text-center
    justify-center
    items-center
    ssm:px-3
    ssm:h-full
    md:h-[60rem]
}

.education-item:hover {
    background: linear-gradient(162deg, rgba(26,30,86,1) 0%, rgba(55,23,88,1) 40%, rgba(63,40,126,1) 68%, rgba(26,87,134,1) 100%);
}

.education-item img {
    @apply
    ssm:h-[8rem]
    ssm:w-[8rem]
    sm:h-[11rem]
    sm:w-[11rem]
    md:h-[22rem]
    md:w-[22rem]
    lg:h-[26rem]
    lg:w-[26rem]
    rounded-full
    text-center
}

.education-item h3 {
    @apply
    lg:text-4xl
    md:text-3xl
    text-theme-bright2-white
    font-bold
    mt-5
    ssm:text-xl
}

.education-item p {
    @apply
    lg:text-3xl
    md:text-2xl
    text-theme-bright1-white
    font-bold
    mt-5
    ssm:text-lg
}

.education-item pre{
    @apply
    lg:text-2xl
    md:text-xl
    text-gray-400
    font-bold
    mt-5
    ssm:text-lg
}

.education-item a {
    @apply
    lg:text-2xl
    md:text-xl
    text-pink-600
    border-2
    border-pink-600
    rounded-full
    px-5    
    py-2
    hover:bg-pink-600
    hover:text-white
    transition-all
    duration-300
    whitespace-nowrap
    ssm:text-lg
}