.welcome-container {
    @apply
    max-w-[1000px]
    mx-auto
    px-8
    flex
    flex-col
    justify-center
    h-screen
    ;
}

.welcome-container h6 {
    @apply
    text-pink-600
    lg:text-2xl
}

.welcome-container h1 {
    @apply
    text-5xl
    sm:text-7xl
    font-bold
    text-theme-bright2-white
    pb-5
}

.welcome-container h2 {
    @apply
    lg:text-4xl 
    ssm:text-xl 
    font-bold 
    text-theme-bright1-white
}

.welcome-container h3 {
    @apply
    lg:text-4xl
    sm:text-7xl
    font-bold
    text-theme-bright1-white
}

.welcome-container p {
    @apply
    text-theme-bright1-white
    py-4
    max-w-[700px]
    text-justify
    lg:text-2xl
}
