.education-items-container {
    @apply
    grid
    md:grid-flow-col
    ssm:grid-flow-row
    ssm:flex-col
    md:flex-row
    justify-center
    md:mx-40
    gap-10
    h-[fit-content]
    
}