.nav-button {
  @apply text-white
    border-2
    px-6
    py-3
    my-2
    flex
    items-center
        capitalize
    hover:bg-pink-600
    hover:border-pink-600
    w-[12rem];
}

.nav-arrow {
  @apply ml-3;
}
